<template lang="pug" functional>
  div
    .department-item-skeleton(
      v-for="i in props.count" :key="i")
      .department-item-skeleton__avatar
      .department-item-skeleton__content
        .department-item-skeleton__title
</template>

<script>
export default {
  name: 'DepartmentItemSkeleton',

  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .department-item-skeleton {
    padding: 1rem;
    display: flex;
    align-items: center;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__avatar {
      height: 40px;
      width: 40px;
      margin-right: 1rem;
      border-radius: 50%;
      @include skeleton;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__title {
      width: 140px;
      height: 15px;
      border-radius: 5px;
      @include skeleton;
    }
  }
</style>
